export const menuItems = [
  {
    name: "apps",
    label: "all apps",
  },
  {
    name: "submission/create",
    label: "submit app"
  },
  {
    name: "about",
    label: "about"
  },
  {
    name: "https://techprimate.com/contact",
    label: "contact",
    isExternal: true
  }
];
